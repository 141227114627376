<template>
  <div class="hms-checkin-visitor">
    <div class="header">
      <a-breadcrumb style="margin-bottom: 20px">
        <a-breadcrumb-item>健康系统</a-breadcrumb-item>
        <a-breadcrumb-item>访客体温记录</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row>
        <a-col :span="18">
          <a-select
            default-value="createTime"
            style="width: 150px; margin-right: 10px"
            @change="handleQueryTypeSelectOptionChange"
          >
            <a-select-option value="createTime"> 登记时间 </a-select-option>
          </a-select>
          <a-range-picker
            @change="
              (date, dateString) => {
                this.queryDate = dateString;
              }
            "
          />
          <a-button
            type="primary"
            icon="search"
            style="margin-left: 10px"
            @click="handleQueryDateBtnClick"
            >查询</a-button
          >
          <a-button
            type="default"
            icon="export"
            style="margin-left: 10px"
            :disabled="dataSourceList.length === 0"
            @click="handleDataSourceExport"
            >数据导出</a-button
          >
        </a-col>
        <a-col :span="6">
          <a-input-search
            v-model="dataSourceSearchInput"
            placeholder="请输入任何想搜索的内容"
            allowClear
            @search="handleDataSourceSearch"
          />
        </a-col>
      </a-row>
    </div>
    <div class="body">
      <a-table
        rowKey="id"
        :data-source="getCurrentDataSourceList"
        :pagination="paginationOptions"
        @change="
          (pagination) => {
            paginationOptions.current = pagination.current;
            paginationOptions.pageSize = pagination.pageSize;
          }
        "
        :scroll="{ x: 1200 }"
        bordered
        size="small"
      >
        <a-table-column key="index" title="序号" :width="60" data-index="index" fixed="left">
          <template slot-scope="text, record, index">
            <span>{{
              (paginationOptions.current - 1) * paginationOptions.pageSize +
              parseInt(index) +
              1
            }}</span>
          </template>
        </a-table-column>
        <a-table-column
          key="name"
          title="姓名"
          :width="100"
          data-index="name"
		  fixed="left"
        />
        <a-table-column
          key="phone"
          title="手机号"
          :width="140"
          data-index="phone"
        />
        <a-table-column
          key="gender"
          title="性别"
          :width="80"
          data-index="gender"
        />
        <a-table-column
          key="temperature"
          title="体温数值"
          :width="80"
          data-index="temperature"
        />
        <a-table-column
          key="company"
          title="就职公司"
          :width="300"
          data-index="company"
        />
        <a-table-column
          key="visitCompany"
          title="到访公司"
          :width="300"
          data-index="visitCompany"
        />
        <a-table-column
          key="purpose"
          title="到访目的"
          :width="300"
          data-index="purpose"
        />
        <a-table-column
          key="idcard"
          title="身份证号"
          :width="200"
          data-index="idcard"
        />
        <a-table-column
          key="createTime"
          title="登记时间"
          :width="200"
          data-index="createTime"
        />
		<a-table-column key="operation" title="操作" :width="100" data-index="operation" fixed="right">
		  <template slot-scope="text, record">
		    <a-popconfirm
		      title="确定要删除此记录?"
		      @confirm="handleTableDeleteBtnClick(record.id)"
		    >
		      <a>删除</a>
		    </a-popconfirm>
		  </template>
		</a-table-column>
      </a-table>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import PaginationOptions from "@/options/pagination.js";
import FiltrationOptions from "@/options/filtration.js";
import HmsCheckinVisitorApi from "@/api/hms/hms-checkin-visitor.js";
import ExportUtil from "@/utils/export.js";
import { mapState } from "vuex";

export default {
  name: "HmsCheckinVisitor",
  data() {
    return {
      paginationOptions: PaginationOptions,
      filtrationOptions: FiltrationOptions,
      queryType: "1",
      queryDate: [],
      dataSourceList: [],
      dataSourceCurrent: {},
      dataSourceSearchInput: "",
      dataSourceSearchList: [],
      dataSourceSearchValid: false,
    };
  },
  computed: {
    ...mapState({}),
    getCurrentDataSourceList: function () {
      return this.dataSourceSearchValid
        ? this.dataSourceSearchList
        : this.dataSourceList;
    },
  },
  watch: {
    dataSourceSearchInput: function (val) {
      if (val === null || val === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
      }
    },
  },
  mounted: function () {},
  methods: {
    fetchCheckinVisitorList: function (startTime, endTime) {
      HmsCheckinVisitorApi.listByStartTimeAndEndTime(
        startTime + " 00:00:00",
        endTime + " 23:59:59"
      ).then((response) => {
        if (response.status === "SUCCESS") {
          this.dataSourceList = response.body;
          if (this.dataSourceList.length === 0) this.$message.success("无数据");
        } else {
          this.dataSourceList = [];
        }
      });
    },
    handleQueryTypeSelectOptionChange: function (value) {
      const temp = ["createTime"];
      this.queryType = (temp.indexOf(value) + 1).toString();
    },
    handleQueryDateBtnClick: function () {
      if (!this.queryDate || this.queryDate.length === 0) {
        this.$message.error("请先选择日期后再查询！");
        return;
      }
      this.fetchCheckinVisitorList(this.queryDate[0], this.queryDate[1]);
    },
    handleTableChange(pagination) {
      this.paginationOptions.current = pagination.current;
      this.paginationOptions.pageSize = pagination.pageSize;
    },
	handleTableDeleteBtnClick: function(id) {
	  HmsCheckinVisitorApi.deleteById(id).then((response) => {
	    this.$message.success(response.message);
	    if (response.status === "SUCCESS") {
	      this.fetchCheckinVisitorList(this.queryDate[0], this.queryDate[1]);
	    }
	  });
	},
    handleDataSourceSearch: function (value) {
      if (value === null || value === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
        return;
      }
      this.dataSourceSearchList = this.dataSourceList.filter((item) => {
        return JSON.stringify(item).indexOf(value.trim()) !== -1;
      });
      this.dataSourceSearchValid = true;
    },
    handleDataSourceExport: function () {
      const columns = [
        {
          title: "姓名",
          key: "name",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "性别",
          key: "gender",
        },
        {
          title: "体温",
          key: "temperature",
        },
        {
          title: "就职公司",
          key: "company",
        },
        {
          title: "到访公司",
          key: "visitCompany",
        },
        {
          title: "到访目的",
          key: "purpose",
        },
        {
          title: "身份证号",
          key: "idcard",
        },
        {
          title: "登记时间",
          key: "createTime",
        },
      ];

      // 整理数据
      var list = [];
      for (let i = 0, j = this.dataSourceList.length; i < j; i++) {
        const item = {};
        for (let m = 0, n = columns.length; m < n; m++) {
          item[columns[m].key] = this.dataSourceList[i][columns[m].key];
        }
        list.push(item);
      }

      ExportUtil.export2Excel(columns, list, "访客体温记录表");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}
</style>
